<template>
  <div class="banner-container">
    <!-- arrow="always" -->
    <el-carousel :interval="4000" class="banner-carousel">
      <el-carousel-item v-for="(item, i) in swiperList" :key="i">
        <el-image
          :src="item.img"
          alt=""
          style="width: 100%; height: 100%"
          fit="cover"
        />
      </el-carousel-item>
    </el-carousel>
    <div class="banner-text-container">
      <div class="banner-text1">智慧能源</div>
      <div class="banner-text2">解决方案及供应商</div>
      <div class="banner-text3">
        <img
          class="item-arrow"
          src="@/assets/home/item-arrow.png"
          alt=""
        /><span>进一步了解</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  components: {},
  props: {},
  watch: {},
  data() {
    return {
      swiperList: [
        {
          img: require("@/assets/banner/banner1.png"),
        },
        {
          img: require("@/assets/banner/banner2.png"),
        },
      ],
    };
  },

  mounted() {},
  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.banner-container {
  width: 100%;
  height: 100vh;
  position: relative;

  .banner-carousel {
    width: 100%;
    height: 100%;
    /deep/ .el-carousel__container {
      height: 100%;
    }
  }

  .banner-text-container {
    position: absolute;
    z-index: 99;
    width: 600px;
    left: 100px;
    height: 120px;
    top: 50%;
    color: #fff;
    transform: translateY(-120px);
    .banner-text1 {
      font-size: 58px;
      margin-bottom: 20px;
    }
    .banner-text2 {
      font-size: 58px;
      margin-bottom: 20px;
    }
    .banner-text3 {
      font-size: 20px;
      .item-arrow {
        margin-right: 20px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
</style>
