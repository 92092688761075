<template>
  <div class="home-part-three-list">
    <div class="home-part-three-item home-part-three-item1" @click="toDetail({path: '/szdl/1'})">
      <div class="home-part-three-inner">
        <div class="inner-content">
          <div class="inner-content-title">配电站房智能辅助系统</div>
          <div class="inner-content-line">
            <img
              class="line-img"
              src="@/assets/home/three-part-line-2.png"
              alt=""
            />
          </div>
          <div class="inner-content-text">
            更全面的辅助系统实现配电站房智能化
          </div>
          <div class="inner-content-more">
            <img class="item-arrow" src="@/assets/home/item-arrow.png" alt="" />
            <span>进一步了解</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-part-three-item home-part-three-item2" @click="toDetail({path: '/szdl/2'})">
      <div class="home-part-three-inner">
        <div class="inner-content">
          <div class="inner-content-title">变电站/电厂立体巡检系统</div>
          <div class="inner-content-line">
            <img
              class="line-img"
              src="@/assets/home/three-part-line-1.png"
              alt=""
            />
          </div>
          <div class="inner-content-text">更智能的巡检系统实现变电站巡检立体化</div>
          <div class="inner-content-more">
            <img class="item-arrow" src="@/assets/home/item-arrow.png" alt="" />
            <span>进一步了解</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-part-three-item home-part-three-item3" @click="toDetail({path: '/szdl/3'})">
      <div class="home-part-three-inner">
        <div class="inner-content">
          <div class="inner-content-title">智慧配电网</div>
          <div class="inner-content-line">
            <img
              class="line-img"
              src="@/assets/home/three-part-line-4.png"
              alt=""
            />
          </div>
          <div class="inner-content-text">更丰富的解决方案实现配电网运行智慧化</div>
          <div class="inner-content-more">
            <img class="item-arrow" src="@/assets/home/item-arrow.png" alt="" />
            <span>进一步了解</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-part-three-item home-part-three-item4" @click="toDetail({path: '/szdl/4'})">
      <div class="home-part-three-inner">
        <div class="inner-content">
          <div class="inner-content-title">智融党建</div>
          <div class="inner-content-line">
            <img
              class="line-img"
              src="@/assets/home/three-part-line-3.png"
              alt=""
            />
          </div>
          <div class="inner-content-text">更先进的线上管理实现党建工作数字化</div>
          <div class="inner-content-more">
            <img class="item-arrow" src="@/assets/home/item-arrow.png" alt="" />
            <span>进一步了解</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    toDetail(item) {
      if(this.$route.path != item.path){
        this.$router.push({
          path: item.path
        });
      }
    },
  }

};  
</script>

<style lang="scss" scoped>
.home-part-three-list {
  width: 100%;
  height: 646px;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 20px 80px;
  .home-part-three-item {
    flex-basis: 23%;
    height: 646px;
    border-radius: 42px;
    overflow: hidden;
    cursor: pointer;
    .home-part-three-inner {
      width: 100%;
      height: 100%;
      position: relative;
      background-image: url("../../../assets/home/three-part-mb-1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      &:hover {
        background-image: url("../../../assets/home/three-part-mb-2.png");
      }
      .inner-content {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        padding: 100px 50px;
        color: #ffffff;
        .inner-content-title {
          width: 100%;
          height: 30px;
          font-size: 18px;
        }
        .inner-content-line {
          width: 100%;
          height: 2px;
          margin-bottom: 20px;
          .line-img {
            width: 100%;
            height: 100%;
          }
        }
        .inner-content-text {
          width: 100%;
          font-size: 32px;
          margin-bottom: 20px;
        }
        .inner-content-more {
          width: 100%;
          display: flex;
          font-size: 20px;
          color: #fff;
          .item-arrow {
            width: 32px;
            height: 20px;
            margin-right: 10px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .home-part-three-item1 {
    background-image: url("../../../assets/home/three-part-item-1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-part-three-item2 {
    background-image: url("../../../assets/home/three-part-item-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-part-three-item3 {
    background-image: url("../../../assets/home/three-part-item-3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-part-three-item4 {
    background-image: url("../../../assets/home/three-part-item-4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>