<template>
  <div>
    <Nav />
    <Banner />
    <div class="container">
      <div class="part-common home-first-part">
        <section-title :titleInfo="firstPartTitleInfo"></section-title>
        <div class="first-part-zhibiao">
          <div class="zhibiao-item zhibiao-border">
            <div class="zhibiao-value">25+</div>
            <div class="zhibiao-name">专利证书</div>
          </div>
          <div class="zhibiao-item zhibiao-border">
            <div class="zhibiao-value">28+</div>
            <div class="zhibiao-name">软件著作</div>
          </div>
          <div class="zhibiao-item">
            <div class="zhibiao-value">30+</div>
            <div class="zhibiao-name">品牌荣誉</div>
          </div>
        </div>
      </div>
      <div class="part-common home-second-part">
        <section-title :titleInfo="secondPartTitleInfo"></section-title>

        <div class="part-control">
          <div class="control-part left-control" @click="handleLeftControl">
            <img
              class="control-part-pic"
              src="@/assets/home/left-control.png"
              alt=""
            />
          </div>
          <div class="show-index">
            <span class="now-index">{{ nowShowIndex }}</span> / 04
          </div>
          <div class="control-part right-control" @click="handleRightControl">
            <img
              class="control-part-pic"
              src="@/assets/home/right-control.png"
              alt=""
            />
          </div>
        </div>

        <div class="home-second-part-main">
          <home-part-two :showIndex="nowIndex"></home-part-two>
        </div>
      </div>
      <div class="part-common home-three-part">
        <section-title :titleInfo="threePartTitleInfo"></section-title>
        <div class="home-three-part-main">
          <home-part-three></home-part-three>
        </div>
      </div>

      <div class="part-common home-four-part">
        <section-title :titleInfo="fourPartTitleInfo"></section-title>

        <img
          class="four-part-pic"
          src="@/assets/home/home-part-four-pic2.png"
          alt=""
        />

        <home-part-four class="home-four-main"></home-part-four>
      </div>
    </div>
    <Footer class="top" />
  </div>
</template>

<script>
import Banner from "../components/Banner";
import HomePartFour from "../components/home/home-part-four.vue";
import HomePartThree from "../components/home/home-part-three.vue";
import HomePartTwo from "../components/home/home-part-two.vue";
import SectionTitle from "../components/section-title.vue";

export default {
  name: "Home",
  components: {
    Banner,
    SectionTitle,
    HomePartTwo,
    HomePartThree,
    HomePartFour,
  },
  props: {},
  watch: {},
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "做国内一流的智慧能源",
        bigtext2: "解决方案及服务提供商",
        smallText:
          "智融科技 秉着“专注聚焦、创新创业、合作开放、诚实守信”的企业理念，将传统电力自动化技术与物联网、大数据、边缘计算、人工智能、5G等先进的信息通 信技术深度结合，在新能源发电、调度自动化、变电站综合自动化、配电自动化、智慧站房、综合能源服务、电能量计量计费、电力信息化建设等领域为客户提供从端到云的全方位解决方案。",
        showKnowMore: true,
      },
      secondPartTitleInfo: {
        bigtext1: "智能运营",
        smallText: "持续为超过10万+客户提供智能运营服务。",
        showKnowMore: false,
      },
      threePartTitleInfo: {
        bigtext1: "数字电力",
        smallText: "持续为超过10万+客户提供智能运营服务。",
        showKnowMore: false,
      },
      fourPartTitleInfo: {
        bigtext1: "联系我们",
        smallText: "江苏智融能源科技有限公司",
        showKnowMore: false,
        color: "white",
      },
      nowIndex: 0,
    };
  },

  computed: {
    nowShowIndex() {
      return "0" + (this.nowIndex + 1);
    },
  },

  mounted() {},

  created() {},

  methods: {
    handleLeftControl() {
      if (this.nowIndex == 0) {
        this.nowIndex = 3;
      } else {
        this.nowIndex = this.nowIndex - 1;
      }
    },

    handleRightControl() {
      if (this.nowIndex == 3) {
        this.nowIndex = 0;
      } else {
        this.nowIndex = this.nowIndex + 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .part-common {
    width: 100%;
    height: 1080px;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }
  .home-first-part {
    background-image: url("../../assets/home/home-part1-bg.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .first-part-zhibiao {
      position: absolute;
      width: 400px;
      top: 320px;
      right: 160px;
      .zhibiao-item {
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        .zhibiao-value {
          width: 160px;
          font-weight: bold;
          font-size: 32px;
          color: #1f63ea;
        }
        .zhibiao-name {
          font-weight: 400;
          font-size: 22px;
          color: #1d1d1d;
        }
      }
      .zhibiao-border {
        border-bottom: 2px dotted #d4e6ff;
      }
    }
  }
  .home-second-part {
    background-color: #f6f6f6;
    .home-second-part-main {
      width: 1500px;
      position: absolute;
      bottom: -54px;
      right: 0;
      z-index: 9;
    }
    .part-control {
      position: absolute;
      width: 100px;
      height: 200px;
      left: 100px;
      top: 60%;
      .control-part {
        width: 64px;
        height: 64px;
        cursor: pointer;
        .control-part-pic {
          width: 100%;
          height: 100%;
        }
      }
      .show-index {
        width: 64px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        font-size: 16px;
        color: rgba(#1f63ea, 0.6);
        .now-index {
          font-size: 18px;
          color: #1f63ea;
        }
      }
    }
  }
  .home-three-part {
    background-color: #fff;
    .home-three-part-main {
      width: 100%;
      height: 650px;
      position: absolute;
      left: 0;
      bottom: 100px;
    }
  }
  .home-four-part {
    background-image: url("../../assets/home/home-part-four-bg.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .four-part-pic {
      position: absolute;
      width: 578px;
      height: 115px;
      right: 160px;
      top: 160px;
    }
    .home-four-main {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
