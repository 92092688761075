import { render, staticRenderFns } from "./home-part-two.vue?vue&type=template&id=8d0077e8&scoped=true"
import script from "./home-part-two.vue?vue&type=script&lang=js"
export * from "./home-part-two.vue?vue&type=script&lang=js"
import style0 from "./home-part-two.vue?vue&type=style&index=0&id=8d0077e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d0077e8",
  null
  
)

export default component.exports