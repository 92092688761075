<template>
  <div class="home-part-two">
    <img
      class="home-part-pic1"
      src="@/assets/home/home-part-two-pic1.png"
      alt=""
    />

    <div class="partInfo">
      <div class="partinfo-title">
        <span class="title-cont">{{ showPartInfo.title }}</span>
        <img class="title-pic" src="@/assets/home/item-arrow.png" alt="" />
      </div>

      <div class="part-info-list">
        <div
          class="part-info-item"
          v-for="(item, index) in showPartInfo.infoList"
          :key="index"
          @click="toDetail(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showPartInfo() {
      return this.partInfoList[this.showIndex];
    },
  },
  data() {
    return {
      partInfoList: [
        {
          title: "充电运营",
          infoList: [
            {
              name: "多元智慧微网",
              path: "/zhyw/1",
              query: '1'
            },
            {
              name: "居民小区充电网",
              path: "/zhyw/1",
              query: '2'
            },
            {
              name: "公共充电网",
              path: "/zhyw/1",
              query: '3'
            },
            {
              name: "公路充电网",
              path: "/zhyw/1",
              query: '4'
            },
          ],
        },
        {
          title: "能源聚合",
          infoList: [
            {
              name: "需求响应",
              path: "/zhyw/2",
              query: '1'
            },
            {
              name: "微网管理",
              path: "/zhyw/2",
              query: '2'
            },
          ],
        },
        {
          title: "数据运营",
          infoList: [
            {
              name: "车网互动",
              path: "/zhyw/3",
              query: '1'
            },
            {
              name: "交能融合",
              path: "/zhyw/3",
              query: '2'
            },
          ],
        },
        {
          title: "车辆检测",
          infoList: [
            {
              name: "车辆检测",
              path: "/zhyw/4",
            },
          ],
        },
      ],
    };
  },
  methods:{
    toDetail(item, i) {
      if(this.$route.path != item.path){
        this.$router.push({
          path: item.path,
          query: { defaultIndex: item.query || '1' } 
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.home-part-two {
  width: 100%;
  height: 760px;
  position: relative;
  background-image: url("../../../assets/home/home-part-two-pic2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  .home-part-pic1 {
    width: 730px;
    height: 487px;
    position: absolute;
    top: -141px;
    right: 160px;
  }

  .partInfo {
    width: 300px;
    position: absolute;
    top: 100px;
    left: 100px;
    .partinfo-title {
      width: 100%;
      height: 80px;
      line-height: 80px;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px dotted #67a7ff;
      margin-bottom: 30px;
      .title-cont {
        font-weight: 400;
        font-size: 32px;
        color: #ffffff;
      }
      .title-pic {
        display: inline-block;
        width: 32px;
        height: 20px;
        margin-top: 30px;
      }
    }
    .part-info-list {
      width: 100%;
      .part-info-item {
        margin-bottom: 20px;
        font-size: 18px;
        color: #d4e6ff;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
</style>

