<template>
  <div class="home-part-four">
    <div class="hp-four-main-info">
      <div class="company-main-title">江苏智融能源科技有限公司</div>

      <div class="company-small-title">
        公司地址：南京市雨花台区软件大道118号新华汇B4栋5层
      </div>
      <div class="company-small-title">邮政编码：210012</div>
      <div class="company-small-title">电 话：025-83585700</div>
      <div class="company-small-title">电子邮箱：zhirongtechnology@163.com</div>

      <div class="company-btn">点此咨询加盟/合作</div>
    </div>
    <div class="qrcode-container">
      <img class="qrcode-img" src="@/assets/home/qrcode.png" alt="" />
      <div class="qrcode-text">智融公众号</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.home-part-four {
  width: 1500px;
  height: 760px;
  background-image: url("../../../assets/home/home-part-four-main-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  .hp-four-main-info {
    position: absolute;
    width: 600px;
    top: 301px;
    left: 447px;
    .company-main-title {
      font-weight: bold;
      font-size: 42px;
      color: #1d1d1d;
      margin-bottom: 50px;
    }
    .company-small-title {
      font-weight: 400;
      font-size: 18px;
      color: #1d1d1d;
      margin-bottom: 24px;
    }
    .company-btn {
      width: 280px;
      height: 58px;
      margin-top: 74px;
      text-align: center;
      line-height: 58px;
      font-size: 22px;
      color: #fff;
      background-image: url("../../../assets/home/home-part-four-btn.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }
  .qrcode-container {
    position: absolute;
    width: 108px;
    right: 170px;
    top: 380px;
    .qrcode-img {
      width: 108px;
      height: 108px;
      margin-bottom: 18px;
    }
    .qrcode-text {
      width: 100%;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 18px;
      color: #1d1d1d;
    }
  }
}
</style>